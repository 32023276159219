@media (max-width: 768px) {
    .table {
      border-spacing: 0;
      border: 1px solid rgba(211, 211, 211, 0.5);
      border-radius: 4px;
      font-size: 12px;
      overflow: scroll;
    }
}
  
  .table {
      border-spacing: 0;
      border: 1px solid rgba(211, 211, 211, 0.5);
      border-radius: 4px;
      width: 100%;
      overflow: scroll;
    }
  
    .table tr:last-child td{
      border-bottom: 0;
    }
  
    .table tr:last-child{
      border-right: 0;
    }
  
    .table td:last-child{
      border-right: 0;
    }
  
  
    .table th:last-child{
      border-right: 0;
    }
  
    .table th{
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid rgba(211, 211, 211, 0.5);
      border-right: 1px solid rgba(211, 211, 211, 0.5);
      text-align: center;
    }
  
    .table td{
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid rgba(211, 211, 211, 0.5);
      border-right: 1px solid rgba(211, 211, 211, 0.5);
      text-align: center;
    }