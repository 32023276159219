body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl lg:text-7xl font-black font-playfair;
  }
  h2 {
    @apply text-3xl lg:text-4xl font-bold font-playfair;
  }
  h3 {
    @apply text-xl lg:text-3xl font-bold font-playfair;
  }
  h4 {
    @apply text-xl lg:text-2xl font-bold font-playfair;
  }
  h5 {
    @apply text-lg font-bold font-playfair;
  }
  p {
    @apply text-base font-opensans;
  }

  .bodySmall {
    @apply font-opensans font-normal text-sm not-italic;
  }
  .body {
    @apply font-opensans font-normal text-lg not-italic;
  }
  a {
    @apply hover:underline;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    /* -moz-appearance: textfield !important; */
  }
}
