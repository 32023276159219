.progress {
position: static;
width: 272px;
height: 4px;
left: calc(50% - 272px/2);
top: 0px;

/* Primary */
background: #BDBDBD;
border-radius: 100px;

/* Inside Auto Layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 4px 0px;
}

.progressSelected {
position: static;
width: 272px;
height: 4px;
left: calc(50% - 272px/2);
top: 0px;
        
/* Primary */
background: #3F415B;
border-radius: 100px;
        
/* Inside Auto Layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 4px 0px;
}