.App {
  text-align: center;
  max-width: 1400px;
  margin: auto;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.page-body {
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"), url(./fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"), url(./fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"), url(./fonts/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "PlayfairDisplay";
  font-weight: 400;
  src: local("PlayfairDisplay"),
    url(./fonts/PlayfairDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PlayfairDisplay";
  font-weight: 600;
  src: local("PlayfairDisplay"),
    url(./fonts/PlayfairDisplay-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "PlayfairDisplay";
  font-weight: 700;
  src: local("PlayfairDisplay"),
    url(./fonts/PlayfairDisplay-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "PlayfairDisplay";
  font-weight: 900;
  src: local("PlayfairDisplay"),
    url(./fonts/PlayfairDisplay-Black.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 300;
  src: local("OpenSans"), url(./fonts/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 400;
  src: local("OpenSans"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 700;
  src: local("OpenSans"), url(./fonts/OpenSans-Bold.ttf) format("truetype");
}

.filter-active-icon {
  filter: brightness(0) saturate(100%) invert(22%) sepia(32%) saturate(448%)
    hue-rotate(196deg) brightness(101%) contrast(91%);
}

.filter-inactive-icon {
  filter: brightness(0) saturate(100%) invert(97%) sepia(2%) saturate(299%)
    hue-rotate(201deg) brightness(91%) contrast(90%);
}

.react-multi-carousel-track li {
  width: 150px !important;
}

.truncate-line-two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
}

.z-1001 {
  z-index: 1001;
}
