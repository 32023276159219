.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Poppins';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox {
  position: relative;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  margin-right: 12px;
  border-radius: 2px;
  border: 2px solid #3f415b;
}

.checkbox-container input:checked ~ .checkbox {
  background-color: #3f415b;
  border: 0;
}

.checkbox-container:hover input:not(:checked) ~ .checkbox {
  background-color: rgba(63, 65, 91, 0.25);
  box-shadow: 0 0 0px 5px rgba(63, 65, 91, 0.25);
}

.checkbox-container:hover input:checked ~ .checkbox {
  box-shadow: 0 0 0px 5px #f2cc8f;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkbox:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
