.plus {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #000;
  border-radius: 50%;
}

.plus::before,
.plus::after {
  content: '';
  position: absolute;
  background-color: #fff;
}

.plus::before {
  width: 10px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus::after {
  width: 2px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alt {
  border: none;
  margin: 10px;
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #fff 90deg,
      #000 0
    )
    calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
    calc(50% + var(--b));
}
